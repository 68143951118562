<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="ma5.png"
            alt="logo"
            height="85"
          >
        </b-link>
        <div
          class="text-center"
          style="margin-top: -25px"
        >
          <small>กรอกเบอร์มือถือของคุณ ระบบจะส่งรหัสผ่านใหม่ให้คุณ</small>
        </div>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- Telephone number -->
            <b-form-group
              label="เบอร์โทรศัพท์"
              label-for="tel"
            >
              <validation-provider
                #default="{ errors }"
                name="phone number"
                rules="required"
              >
                <b-form-input
                  id="phone number"
                  v-model="tel"
                  :state="errors.length > 0 ? false : null"
                  name="register-username"
                  placeholder="กรอกเบอร์มือถือ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              รับรหัสผ่าน
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      username: '',
      password: '',
      status: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style scoped>
.btn-facebook {
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(0, 102, 255);
}

.btn-line {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 0;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(81, 255, 0);
}

.btn-google {
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(255, 0, 0);
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
